import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  ViewContainerRef,
} from '@angular/core';
import { BehaviorSubject, debounceTime, Subject, takeUntil } from 'rxjs';
import { IconType } from '../enums/icon.enum';
import { IconComponent } from '../shared/components/icon/icon.component';
import { NavigateComponent } from '../shared/components/navigate/navigate.component';

type Attribute = {
  name: string;
  value: string;
};

@Directive({
    selector: '[countChildrenOverflow]',
    standalone: false
})
export class CountChildrenOverflowDirective
  implements AfterViewInit, OnDestroy
{
  private destroy$ = new Subject<void>();
  private resize$ = new BehaviorSubject<void>(null);
  private childrenClass = 'overflow-child';
  private badgeClass = 'more-items-badge';
  private minDisplayWidth = 70;

  @Input()
  public navigateTo: string;

  constructor(
    private el: ElementRef,
    private vcf: ViewContainerRef,
    private injector: Injector,
  ) {
    this.el.nativeElement.style.overflowX = 'hidden';
  }

  @HostListener('window:resize')
  public onResize() {
    this.resize$.next();
  }

  public ngAfterViewInit(): void {
    this.resize$
      .pipe(takeUntil(this.destroy$), debounceTime(200))
      .subscribe(() => {
        this.hideOverflows();
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private hideOverflows() {
    const children = this.el.nativeElement.querySelectorAll(
      `.${this.childrenClass}:not(.${this.badgeClass})`,
    );

    if (!children?.length) {
      return;
    }

    this.cleanUp(children);

    const { elementWidth, childrenMargins } = this.getWidths(children);
    const unfitCount = this.checkUnfit(children, elementWidth, childrenMargins);

    if (!unfitCount) {
      return;
    }

    this.setBadge(unfitCount, children[0]);
  }

  private setBadge(unfitCount: number, exampleNode: HTMLElement) {
    const attributes: Attribute[] = Array.from(exampleNode.attributes);
    const badge = this.el.nativeElement.querySelector('.' + this.badgeClass);

    if (!badge) {
      const badgeNode = this.getBadge(
        unfitCount,
        exampleNode.tagName,
        attributes,
      );

      this.el.nativeElement.appendChild(badgeNode);
    } else {
      const strong = badge.querySelector('strong');
      strong.innerText = `${unfitCount} more`;
    }
  }

  private cleanUp(children: HTMLElement[]): void {
    children.forEach((child) => {
      child.style.display = '';
      child.style.maxWidth = '';
    });
  }

  private checkUnfit(
    children: HTMLElement[],
    elementWidth: number,
    childMargin: number,
  ): number {
    const badgeNodeWidth = 91;
    let fittingWidth = 0;
    let unfitCount = 0;

    children.forEach((child) => {
      const childWidth = child.clientWidth + childMargin;

      if (fittingWidth + childWidth + this.minDisplayWidth < elementWidth) {
        fittingWidth += childWidth;

        return;
      }

      unfitCount++;
      child.style.display = 'none';
    });

    if (
      unfitCount &&
      fittingWidth + badgeNodeWidth + childMargin > elementWidth
    ) {
      const lastFit = children[children.length - unfitCount - 1];

      if (lastFit) {
        const overflow = fittingWidth + badgeNodeWidth - elementWidth;

        lastFit.title = lastFit.innerText;
        lastFit.style.maxWidth = lastFit.clientWidth - overflow + 'px';
      }
    }

    return unfitCount;
  }

  private getBadge(count: number, tagName: string, attributes: Attribute[]) {
    const node = document.createElement(tagName);
    const strong = document.createElement('strong');

    strong.innerText = `${count} more`;

    const iconComponent = this.vcf.createComponent(IconComponent);
    const navigateComponent = this.vcf.createComponent(NavigateComponent, {
      injector: this.injector,
      projectableNodes: [[iconComponent.location.nativeElement, strong]],
    });

    attributes.forEach((attribute) => {
      node.setAttribute(attribute.name, attribute.value);
    });

    iconComponent.setInput('name', IconType.more);
    navigateComponent.setInput('navigateTo', this.navigateTo);
    node.classList.add(this.badgeClass, 'clickable');
    node.appendChild(navigateComponent.location.nativeElement);

    return node;
  }

  private getWidths(children: HTMLElement[]) {
    const marginLeft = +window
      .getComputedStyle(children[0])
      .marginLeft.replace('px', '');
    const marginRight = +window
      .getComputedStyle(children[0])
      .marginRight.replace('px', '');

    return {
      childrenMargins: marginLeft + marginRight,
      elementWidth: this.el.nativeElement.clientWidth,
    };
  }
}
