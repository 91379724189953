import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'environments/environment';
import { en } from '../../assets/translations';

@Pipe({
    name: 'translate',
    standalone: false
})
export class TranslatePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return 'Empty';
    }

    return en[value] || this.wrapValue(value);
  }

  private wrapValue(value: string): string {
    return environment.translationsReady ? `[[${value}]]` : value;
  }
}
