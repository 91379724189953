import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AppRoutes } from 'src/core/enums/app-routes.enum';

export interface InvalidLinkData {
  title: string;
  message: string;
}

@Component({
    selector: 'app-invalid-link',
    templateUrl: './invalid-link.component.html',
    styleUrls: ['./invalid-link.component.scss'],
    standalone: false
})
export class InvalidLinkComponent {
  public invalidLinkData: InvalidLinkData = {
    title: 'Invalid link',
    message: '',
  };

  constructor(private readonly router: Router) {
    const extrasState = this.router.getCurrentNavigation()?.extras
      ?.state as InvalidLinkData;

    if (extrasState?.title) {
      this.invalidLinkData = extrasState;
    }
  }

  public goHome(): void {
    this.router.navigate([AppRoutes.home]);
  }
}
