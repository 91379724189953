import { Injectable } from '@angular/core';
import { AuthenticationResult } from '@azure/msal-browser';
import { Observable, catchError, throwError } from 'rxjs';
import { Endpoints } from 'src/core/enums/api/api-endpoints.enum';
import { Errors } from 'src/core/enums/api/api-status.enum';
import { AuthMode } from 'src/core/enums/permissions.enum';
import { OrganizationData } from 'src/core/interfaces/organization.interface';
import {
  ConfirmEmailTokenResponse,
  LoginData,
  PasswordForgot,
  PasswordReset,
  UnlockAccount,
  UserLoginResponse,
} from 'src/core/interfaces/user/auth.interface';
import { UserData } from 'src/core/interfaces/user/user.interface';
import { ApiService } from 'src/core/services/api.service';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService extends ApiService {
  logIn(loginData: LoginData): Observable<UserLoginResponse> {
    return this.http.post<UserLoginResponse>(
      this.endpointBuilder(Endpoints.Auth.login),
      loginData,
    );
  }

  logInWithMicrosoftSSO(
    msAuthResult: AuthenticationResult,
  ): Observable<UserLoginResponse> {
    return this.http.post<UserLoginResponse>(
      this.endpointBuilder(Endpoints.Auth.loginWithMicrosoftSSO),
      msAuthResult,
    );
  }

  resetPassword(
    passwordReset: PasswordReset,
    token: string,
  ): Observable<UserLoginResponse> {
    return this.http
      .post<UserLoginResponse>(
        this.endpointBuilder(Endpoints.Auth.resetPassword),
        {
          password: passwordReset.password,
          token,
        },
      )
      .pipe(
        catchError((err) => {
          return throwError(() => ({
            status: `${Errors.ResetPassword}: ${err.error.error.response}`,
          }));
        }),
      );
  }

  forgotPassword(
    passwordForgot: PasswordForgot,
  ): Observable<UserLoginResponse> {
    return this.http
      .post<UserLoginResponse>(
        this.endpointBuilder(Endpoints.Auth.requestResetPasswordEmail),
        passwordForgot,
      )
      .pipe(
        catchError((err) => {
          return throwError(() => ({
            status: `${Errors.ForgotPassword}: ${err.error.error.response}`,
          }));
        }),
      );
  }

  unlockAccount(unlockAccount: UnlockAccount): Observable<string> {
    return this.http.post<string>(
      this.endpointBuilder(Endpoints.Auth.requestUnlockEmail),
      unlockAccount,
    );
  }

  confirmEmailToken(token: string): Observable<ConfirmEmailTokenResponse> {
    return this.http.get<ConfirmEmailTokenResponse>(
      this.endpointBuilder(Endpoints.Auth.confirmEmailToken, token),
    );
  }

  requestEmailToConfirmAccount(
    activateEmail: UnlockAccount,
  ): Observable<UserLoginResponse> {
    return this.http.post<UserLoginResponse>(
      this.endpointBuilder(Endpoints.Auth.requestAccountActivationEmail),
      activateEmail,
    );
  }

  confirmRecover(token: string): Observable<string> {
    return this.http.post<string>(
      this.endpointBuilder(Endpoints.Auth.confirmRecover),
      { token },
    );
  }

  createUser(createUserData: UserData): Observable<UserData> {
    return this.http.post<UserData>(
      this.endpointBuilder(Endpoints.Auth.create),
      createUserData,
    );
  }

  signUp(
    createOrganizationData: OrganizationData,
  ): Observable<OrganizationData> {
    return this.http
      .post<OrganizationData>(
        this.endpointBuilder(Endpoints.Organization.create),
        {
          organizationId: '',
          ...createOrganizationData,
        },
      )
      .pipe(
        catchError((err) => {
          throw err;
        }),
      );
  }

  getOrganizationData(id: string): Observable<OrganizationData> {
    return this.http.get<OrganizationData>(
      this.endpointBuilder(Endpoints.Organization.base, id),
    );
  }

  updateAuthMode(mode: AuthMode): Observable<string> {
    return this.http.patch<string>(
      this.endpointBuilder(Endpoints.Auth.updateAuthMode),
      { mode },
    );
  }

  generateNonce() {
    return this.http.get<string>(
      this.endpointBuilder(Endpoints.Auth.generateNonce),
    );
  }
}
